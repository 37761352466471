<footer>
    <ul>
        <li>Last update: {{ version }}</li>
        <li>&copy; 2022 Chevron Corporation</li>
        <li>Company Confidential</li>
        <li>OpCo: <a href="https://chevron.sharepoint.com/sites/SurfaceDigital">Surface Digital Platform</a></li>
    </ul>
    <ul>
        <li>Content contact: <a>Field Inspections Team</a> </li>
        <li>Technical contact: <a>Field Inspections Team</a>
        </li>
    </ul>
</footer>