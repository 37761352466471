<div class="row">
    <div class="col-12 text-center">
        <h4>
            You have successfully logged out.
        </h4>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <hr />
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="text-center">
            <button type="button" class="btn btn-primary" (click)="signIn()">Sign In</button>
        </div>
    </div>
</div>