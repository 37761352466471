import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  QueryBuilderConfig,
  RuleSet,
  Option,
  Field,
  Rule,
  ConditionEnum,
  User,
} from "src/app/models";
import { LookupService, UserService } from "src/services/api";
import { LoadingIndicatorService } from "src/services/loading-indicator.service";
import { finalize } from "rxjs/operators";
@Component({
  selector: "query-builder",
  templateUrl: "./query-builder.component.html",
  styleUrls: ["./query-builder.component.scss"],
})
export class QueryBuilderComponent implements OnInit {
  @Output() applyFiltersEvent = new EventEmitter<any>();

  public config: QueryBuilderConfig;
  public data: RuleSet = { rules: [] }; // pre selected rows by the user
  public fields: Field[];
  public disabled: boolean;

  public defaultOperatorMap: { [key: string]: string[] } = {
    string: ["=", "!="],
    number: ["=", "!=", ">", ">=", "<", "<="],
    time: ["=", "!=", ">", ">=", "<", "<="],
    date: ["=", "!=", ">", ">=", "<", "<="],
    collection: ["=", "!=", "in", "not in"],
    boolean: ["="],
  };
  constructor(
    private lookupService: LookupService,
    private loaderService: LoadingIndicatorService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.showLoader();
    this.data = this.getDataFromStorage("query-builder:config");
    this.config = {
      fields: {},
    };

    this.userService.getCurrentUser().subscribe((user: User) => {
      this.lookupService
        .getFilterConfig(user.id)
        .pipe(
          finalize(() => {
            this.hideLoader();
          })
        )
        .subscribe((result) => {
          // fill the dropdowns with the data from the API
          result.forEach((entity) => {
            this.config.fields[entity.name] = {
              name: entity.name,
              type: entity.type.toLowerCase(),
              options: entity.options,
              entity: entity.entity,
              searchableField: entity.searchableField,
            };
          });

          if (!this.data) {
            // The first time we login, set as default the first option
            const firstOption = result.length
              ? result[0]
              : {
                  name: "",
                  type: "",
                  options: [],
                  entity: "",
                  searchableField: "",
                };
            this.data = this.setDefaultData(firstOption);
          }

          const type = typeof this.config;
          if (type === "object") {
            this.fields = Object.keys(this.config.fields).map((value) => {
              const field = this.config.fields[value];
              field.value = field.value || value;
              return field;
            });
            this.hideLoader();
            // Apply Filters
            this.btnApplyFilters();
          } else {
            throw new Error(
              `Expected 'config' must be a valid object, got ${type} instead.`
            );
          }
        });
    });
  }

  ngOnChanges() {
    const config = this.config;
    const type = typeof config;
    if (type === "object") {
      this.fields = Object.keys(config.fields).map((value) => {
        const field = config.fields[value];
        field.value = field.value || value;
        return field;
      });
    }
  }

  getOperatorsByType(fieldType: string) {
    return this.defaultOperatorMap[fieldType];
  }

  getOptions(field: string): Array<Option> {
    if (this.config.getOptions) {
      return this.config.getOptions(field);
    }
    return this.config.fields[field].options || new Array<Option>();
  }

  getFields(entity: string): Field[] {
    return this.fields;
  }

  getOperators(field: string): string[] {
    const fieldObject = this.config.fields[field];

    const type = fieldObject ? fieldObject.type : undefined;
    return type ? this.defaultOperatorMap[type] : new Array<string>();
  }

  getInputType(field: string, operator: string): string {
    const type =
      this.config && this.config.fields[field]
        ? this.config.fields[field].type
        : "";
    switch (operator) {
      case "is null":
      case "is not null":
        return null;
      case "in":
      case "not in":
        return type === "collection" || type === "boolean"
          ? "multiselect"
          : type;
      default:
        return type;
    }
  }

  changeField(fieldValue: string, rule: Rule): void {
    if (this.disabled) {
      return;
    }

    const field = this.fields.find((x) => x.name === fieldValue);
    rule.entity = field.entity;
    rule.searchableField = field.searchableField;

    delete rule.value;
    this.removeStoredData();
    this.addDataToStorage(this.data);
  }

  changeInput() {
    this.removeStoredData();
    this.addDataToStorage(this.data);
    return this.config.fields;
  }

  changeOperator(rule: Rule): void {
    if (this.disabled) {
      return;
    }
    rule.value = this.getValueForOperator(rule.operator, rule.value, rule);
  }

  getValueForOperator(operator: string, value: any, rule: Rule): any {
    const inputType: string = this.getInputType(rule.field, operator);
    if (inputType === "multiselect" && !Array.isArray(value)) {
      return [value];
    }
    return value;
  }

  getDisabledState = (): boolean => {
    return this.disabled;
  };

  addRule(parent?: RuleSet): void {
    if (this.disabled) {
      return;
    }
    this.removeStoredData();
    parent = parent || this.data;
    if (this.config.addRule) {
      this.config.addRule(parent);
    } else {
      const field = this.fields[0];
      parent.rules = parent.rules.concat([
        {
          field: field.value,
          operator: this.getDefaultOperator(field),
          value: this.getDefaultValue(field.defaultValue),
          entity: field.entity,
          disabled: false,
          searchableField: field.searchableField,
          condition: ConditionEnum.AND,
        },
      ]);
      this.addDataToStorage(parent);
    }
  }

  getDefaultOperator(field: Field): string {
    if (field && field.defaultOperator !== undefined) {
      return this.getDefaultValue(field.defaultOperator);
    } else {
      const operators = this.getOperators(field.value);
      if (operators && operators.length) {
        return operators[0];
      } else {
        console.warn(
          `No operators found for field '${field.value}'. ` +
            `A 'defaultOperator' is also not specified on the field config. Operator value will default to null.`
        );
        return null;
      }
    }
  }

  getDefaultValue(defaultValue: any): any {
    if (typeof defaultValue == "function") {
      return defaultValue();
    } else {
      return defaultValue;
    }
  }

  btnResetFilter() {
    this.removeStoredData();
    this.data = this.setDefaultData(this.config.fields["Organization"]);
  }

  btnApplyFilters() {
    this.applyFiltersEvent.emit(this.data);
  }

  setDefaultData(field: Field): RuleSet {
    return {
      rules: [
        {
          field: field.name,
          entity: field.entity,
          disabled: true,
          value: field.options.length ? field.options[0].id : null,
          operator: this.defaultOperatorMap["collection"][0],
          searchableField: field.searchableField,
          condition: ConditionEnum.AND,
        },
      ],
    };
  }

  removeRule(rule: Rule, parent?: RuleSet): void {
    if (this.disabled) {
      return;
    }

    parent = parent || this.data;
    parent.rules = parent.rules.filter((r) => r !== rule);
    this.removeStoredData();
    this.addDataToStorage(this.data);
  }

  removeStoredData() {
    localStorage.removeItem("query-builder:config");
  }

  addDataToStorage(data: RuleSet) {
    localStorage.setItem("query-builder:config", JSON.stringify(data));
  }

  getDataFromStorage(key: string) {
    const data = localStorage.getItem(key);
    return JSON.parse(data);
  }

  showLoader() {
    this.loaderService.show();
  }

  hideLoader() {
    this.loaderService.hide();
  }
}
