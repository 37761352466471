import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Organization } from "src/app/models";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "any",
})
export class OrganizationServce {
  constructor(private http: HttpClient) {}

  public getByBU(businessUnitId: number): Observable<Organization[]> {
    return this.http.get<Organization[]>(
      `${environment.apiEndpoint}/organization/getByBU/${businessUnitId}`
    );
  }

  public getByUser(userId: number): Observable<Organization[]> {
    return this.http.get<Organization[]>(
      `${environment.apiEndpoint}/organization/getByUser/${userId}`
    );
  }
}
