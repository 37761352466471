import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BusinessUnit } from "src/app/models";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "any",
})
export class BusinessUnitService {
  constructor(private http: HttpClient) {}

  public getAll(): Observable<BusinessUnit[]> {
    return this.http.get<BusinessUnit[]>(
      `${environment.apiEndpoint}/bu/getAll`
    );
  }

  public getUserBUs(): Observable<BusinessUnit[]> {
    return this.http.get<BusinessUnit[]>(
      `${environment.apiEndpoint}/bu/getUserBUs`
    )
  }
}
