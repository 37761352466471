import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MaterialModule } from "./modules/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { QueryBuilderComponent } from "./components/query-builder/query-builder.component";
import { VintageFrameComponent } from './components/vintage-frame/vintage-frame.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SafePipe } from "./pipes/safe.pipe";

@NgModule({
  imports: [CommonModule, MaterialModule, TranslateModule, FormsModule,MatProgressSpinnerModule],
  declarations: [QueryBuilderComponent, VintageFrameComponent,SafePipe],
  exports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    QueryBuilderComponent,
    VintageFrameComponent,
    SafePipe
  ],
  providers: [
    // nothing here
  ],
})
export class SharedModule {}
