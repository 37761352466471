import { Component } from "@angular/core";
declare var require: any;

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  public version = "";
  
  constructor() {
    this.version = require("../../../../../package.json").version;
  }
}
