<div class="vintage-frame" [style.height]="frameHeight+'px'">
    <!-- tried the following event hooks- (scroll) (loadstart) but (load) is the most consistent -->
    <iframe 
        title="vintage-frame-window"
        id="frame-window"  
        (load)="frameLoadedCallback(this)" 
        [src]="startUrl | safe:'resourceUrl'"
        >
    </iframe>
    

    <div class="overlay-content" *ngIf="isLoading">
        <div class="loader-container">
            <mat-progress-spinner color="blue" mode="indeterminate"></mat-progress-spinner>
            <h3>Loading Legacy Site</h3>
        </div>
    </div>
</div>