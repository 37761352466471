export const environment = {
    production: false,
    SWCacheEnabled: true, //use the builtin service worker cache
    apiEndpoint: "https://mit-api-dev.azure.chevron.com/api/",
    vintageFrameEndpoint: "https://mobileinspections-dev-cvx.azurewebsites.net",


    msalConfig: {
        clientId: '7e69202c-fd37-49a3-9f44-9c650f32807b',
        authority: 'https://login.microsoftonline.com/chevron.onmicrosoft.com',
        redirectUri: 'https://mit-dev.azure.chevron.com',
        postLogoutRedirectUri: 'https://mit-dev.azure.chevron.com/logout/'
    }
};