import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { PromptInfoComponent } from './promptInfo.component'

@NgModule({
  declarations: [PromptInfoComponent],
  imports: [CommonModule, MatDialogModule],
  exports: [PromptInfoComponent]
})
export class PromptInfoModule {}
