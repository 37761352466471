import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { PromptYesNoComponent } from './promptYesNo.component'

@NgModule({
  declarations: [PromptYesNoComponent],
  imports: [CommonModule, MatDialogModule],
  exports: [PromptYesNoComponent]
})
export class PromptYesNoModule {}
