import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { WorkOrder } from "src/app/models";
import { environment } from "../../environments/environment";


@Injectable()
export class WorkOrderService {
    constructor(private http: HttpClient) {

    }

    public getById(workOrderId: number): Observable<WorkOrder> {
        return this.http.get<WorkOrder>(`${environment.apiEndpoint}/workorder/getById/${workOrderId}`);
    }

}
