import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Field, Lookup } from "src/app/models";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "any",
})
export class LookupService {
  constructor(private http: HttpClient) {}

  public getInspectionAssignment(): Observable<Lookup[]> {
    return this.http.get<Lookup[]>(
      `${environment.apiEndpoint}/lookup/inspectionAssignment`
    );
  }

  public getFilterConfig(userId: number): Observable<Array<Field>> {
    return this.http.get<Array<Field>>(
      `${environment.apiEndpoint}/lookup/getFilterConfig/${userId}`
    );
  }
}
