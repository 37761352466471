import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from "@angular/router"
import { LoadingIndicatorService } from "../services/loading-indicator.service"

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(private translateService: TranslateService, private router: Router, private loadingIndicatorService: LoadingIndicatorService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translateService.setDefaultLang("en");

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translateService.use("en");

    this.router.events.subscribe((event: Event) => {
      if(event instanceof NavigationStart){
        this.loadingIndicatorService.show();
      }

      if(event instanceof NavigationEnd){
        this.loadingIndicatorService.hide();
      }

      if(event instanceof NavigationError){
        this.loadingIndicatorService.hide();
      }
    })

  }
}
