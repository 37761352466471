import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Assignment } from "src/app/models";
import { environment } from "../../environments/environment";


@Injectable()
export class AssignmentService {
  constructor(private http: HttpClient) {

  }

  public getByWorkOrder(workOrderId: number): Observable<Assignment> {
    return this.http.get<Assignment>(`${environment.apiEndpoint}/assignment/getByWorkOrder/${workOrderId}`);
  }

}
