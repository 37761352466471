import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-yes-no-prompt',
  templateUrl: './promptYesNo.component.html',
  styleUrls: ['./promptYesNo.component.scss']
})
export class PromptYesNoComponent{
  //#region 'Angular Life Cycle'
  constructor(public dialogRef: MatDialogRef<PromptYesNoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
  //#endregion 'Angular Life Cycle'

  closeYes(){
    this.dialogRef.close(true);
  }

  closeNo(){
    this.dialogRef.close(false);
  }
}
