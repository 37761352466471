// PACKAGE IMPORTS
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { NOTIFICATION } from "src/app/enums/notification-type.enum";
import { NotificationBannerComponent } from "../components/notification-banner/notification-banner.component";

@Injectable({
  providedIn: "any",
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private snackBar: MatSnackBar) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      finalize(() => {}),
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 400:
          case 500:
            this.snackBar.openFromComponent(NotificationBannerComponent, {
              duration: 5000,
              data: {
                title: "Error",
                message: error.error,
                type: NOTIFICATION.Error,
              },
            });
            break;
        }
        return throwError(error.message);
      })
    );
  }
}
