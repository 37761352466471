export interface IOrganization {
  id: number;
  code: string;
  description: string;
}

export class Organization implements IOrganization {
  public id = 0;
  public code = "";
  public description = "";

  constructor(params?: IOrganization) {
    if (params) {
      this.id = params.id || this.id;
      this.code = params.code || this.code;
      this.description = params.description || this.description;
    }
  }
}

//version of Organization that adds disabled parameter for use in dropdowns
export class OrganizationDropdown extends Organization {
  disabled: boolean;

  constructor(params: IOrganization, disabled: boolean) {
    super(params);
    this.disabled = disabled;
  }
}