import { Component, OnInit, HostListener } from "@angular/core";
import { UserService } from "src/services/api";
import { User } from "src/app/models";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from "@azure/msal-browser";
import { filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { LANGUAGES } from "src/app/enums/languages.enum";
import { TranslateService } from "@ngx-translate/core";
import { Router } from '@angular/router';
import { LoadingIndicatorService } from 'src/services/loading-indicator.service';

class HeaderSubItem {
  title: string;
  link: string;

  constructor(title: string, link: string){
    this.title = title;
    this.link = link;
  }
}

class HeaderItem {
  title: string;
  link: string;
  hasSubMenu: boolean;
  subMenuItems: HeaderSubItem[]

  constructor(title: string, link: string, hasSubMenu: boolean, subMenuItems?: HeaderSubItem[]){
    this.title = title;
    this.link = link;
    this.hasSubMenu = hasSubMenu;
    this.subMenuItems = subMenuItems;
  }
}

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  loggedInUser: Observable<User>;
  languagesList: Array<string>;
  languageSelected: string;
  width: number;

  //dynamic header definitions, html will use it in the burger menu or the nav bar depending on view witdh
  headerItems: HeaderItem[] = [
    new HeaderItem("MENU.inspections", "", false),
    new HeaderItem("MENU.equipment", "", false),
    new HeaderItem("MENU.actions", "", false),
    new HeaderItem("MENU.dashboard", "", false),
    new HeaderItem("MENU.administration", "", true, [
      new HeaderSubItem("MENU.users", "/user")
    ]),
    new HeaderItem("MENU.demo", "", true, [
      new HeaderSubItem("MENU.documentation", "/demo/documentation"),
      new HeaderSubItem("loader", "/loaderDemo"),
      new HeaderSubItem("controls", "/demo/controls")
    ])
  ]

  constructor(
    private userService: UserService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private translateService: TranslateService,
    private router: Router,
    private loaderIndicator: LoadingIndicatorService
  ) {
    this.languagesList = Object.keys(LANGUAGES);
    this.languageSelected = this.languagesList[0];
  }

  ngOnInit(): void {
    this.width = window.innerWidth

    console.log(this.headerItems)
   
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        if (this.isLoggedIn()) {
          this.loggedInUser = this.userService.getCurrentUser();
        }
      });
  }

  isLoggedIn(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  onLanguageChange() {
    this.translateService.use(LANGUAGES[this.languageSelected]);
  }

  @HostListener('window:resize', ['$event'])
  onResize(){
    this.width = window.innerWidth
  }

  navigate(url: string) {
    this.router.navigate([url])
  }

}
