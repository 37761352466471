import { Component, OnInit } from '@angular/core';
import { LoadingIndicatorService } from 'src/services/loading-indicator.service';
import { MatDialog, MatDialogRef} from '@angular/material/dialog';
import { PromptYesNoComponent } from 'src/app/core/components/promptYesNo/promptYesNo.component';
import { PromptInfoComponent } from 'src/app/core/components/promptInfo/promptInfo.component';

@Component({
  selector: 'app-loader-demo',
  templateUrl: './loaderDemo.component.html',
  styleUrls: ['./loaderDemo.component.scss']
})
export class LoaderDemoComponent {

    constructor(private loaderIndicator: LoadingIndicatorService, private dialog: MatDialog){}

    //Example of showing the loader for 5 seconds then closing it
    public async loaderOn(){
        this.loaderIndicator.show();
        await this.delay(5000)
        this.loaderIndicator.hide();
    }

    private delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

    //Opens the yes/no dialog and subscribes to the result that is emitted when closed. clicking no returns false, clicking yes returns true
    public openYesNo(){
        let dialogRef = this.dialog.open(PromptYesNoComponent, {
            width: '350px',
            data: {title: 'Sample Yes/No Dialog', content: 'sample content', noButtonText: 'Decline', yesButtonText: 'Accept'}
        })

        //use the ref created to subscribe to the result emitted when user clicks either button
        dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
        })
    }

    //Opens the info dialog. This dialog is only used for showing the user information, no callback to subscribe to 
    public openInfo(){
        this.dialog.open(PromptInfoComponent, {
            width: '350px',
            data: {title: 'Sample Info Dialog', content: 'Im making this really long so I can see what it makes my dialog look like when there are many words and characters', buttonText: 'this says whatever I want'}
        })
    }

}
