export enum ConditionEnum {
  AND = "AND",
  OR = "OR",
}
export interface RuleSet {
  rules: Array<RuleSet | Rule>;
}
export interface Rule {
  field: string;
  value?: any;
  operator?: string;
  condition: string;
  entity?: string;
  disabled: boolean;
  searchableField: string;
}

export interface Option {
  id: number;
  name: string;
  value: any;
}

export interface FieldMap {
  [key: string]: Field;
}

export interface Field {
  name: string;
  value?: string;
  type: string;
  nullable?: boolean;
  options?: Option[];
  operators?: string[];
  defaultValue?: any;
  defaultOperator?: any;
  entity: string;
  searchableField: string;
}

export interface QueryBuilderConfig {
  fields: FieldMap;
  getOptions?: (field: string) => Option[];
  calculateFieldChangeValue?: (
    currentField: Field,
    nextField: Field,
    currentValue: any
  ) => any;

  getOperators?: (fieldName: string, field: Field) => string[];
  getInputType?: (field: string, operator: string) => string;
  addRuleSet?: (parent: RuleSet) => void;
  addRule?: (parent: RuleSet) => void;
}
export interface FieldContext {
  onChange: (fieldValue: string, rule: Rule) => void;
  getFields: (entityName: string) => void;
  getDisabledState: () => boolean;
  fields: Field[];
  $implicit: Rule;
}

export interface Option {
  name: string;
  value: any;
}
