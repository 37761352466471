import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "./components/header/header.component";
import { RouterModule } from "@angular/router";
import { SharedModule } from "src/app/shared/shared.module";
import { FooterComponent } from "./components/footer/footer.component";
import { LoadingIndicatorModule } from "./components/loading-indicator/loading-indicator.module";
import { PromptYesNoModule } from "./components/promptYesNo/promptYesNo.module";
import { PromptInfoModule } from "./components/promptInfo/promptInfo.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpErrorInterceptor } from "./interceptors/error-handler.interceptor";
import { NotificationBannerComponent } from "./components/notification-banner/notification-banner.component";

@NgModule({
  imports: [CommonModule, RouterModule, SharedModule, HttpClientModule],
  declarations: [HeaderComponent, FooterComponent, NotificationBannerComponent],
  exports: [
    HeaderComponent,
    FooterComponent,
    LoadingIndicatorModule,
    PromptYesNoModule,
    PromptInfoModule,
    NotificationBannerComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error(
        "CoreModule is already loaded. Import it in the AppModule only"
      );
    }
  }
}
