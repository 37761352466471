export interface IBusinessUnit {
  Id: number;
  Name: string;
  Description: string;
}

export class BusinessUnit implements IBusinessUnit {
  public Id = 0;
  public Name = "";
  public Description = "";

  constructor(params?: IBusinessUnit) {
    if (params) {
      this.Id = params.Id || this.Id;
      this.Name = params.Name || this.Name;
      this.Description = params.Description || this.Description;
    }
  }
}
