import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Equipment } from "src/app/models";
import { environment } from "../../environments/environment";


@Injectable()
export class EquipmentService {
    constructor(private http: HttpClient) {

    }

    public getByWorkOrder(workOrderId: number): Observable<Equipment> {
        return this.http.get<Equipment>(`${environment.apiEndpoint}/equipment/getByWorkOrder/${workOrderId}`);
    }

}
