import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-info-prompt',
  templateUrl: './promptInfo.component.html',
  styleUrls: ['./promptInfo.component.scss']
})
export class PromptInfoComponent{
  //#region 'Angular Life Cycle'
  constructor(public dialogRef: MatDialogRef<PromptInfoComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
  //#endregion 'Angular Life Cycle'
}
