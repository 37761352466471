import { Component, Inject } from "@angular/core";
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from "@angular/material/snack-bar";
import { NOTIFICATION } from "src/app/enums/notification-type.enum";

export interface Notification {
  title: string;
  message: string;
  type: NOTIFICATION;
}

@Component({
  selector: "app-notification",
  templateUrl: "./notification-banner.component.html",
  styleUrls: ["./notification-banner.component.scss"],
})
export class NotificationBannerComponent {
  public color: string;
  constructor(
    public snackBarRef: MatSnackBarRef<NotificationBannerComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public config: Notification
  ) {
    snackBarRef.containerInstance.snackBarConfig.panelClass = [
      "mat-toolbar",
      `${config.type}-snackbar`,
    ];
  }
}
