import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, Query } from "@angular/core";
import { Observable } from "rxjs";
import { User } from "src/app/models";
import { PaginatedUser } from 'src/app/models/pagination/paginatedUser';
import { environment } from "../../../../environments/environment";
import { QueryStringParameters } from "src/app/shared/models/query-string-parameters.model";

@Injectable({
  providedIn: "any",
})
export class UserService {
  constructor(private http: HttpClient) {}

  public getCurrentUser(): Observable<User> {
    return this.http.get<User>(`${environment.apiEndpoint}/user/current`);
  }

  public getByOrganization(organizationIds: number[], pageParams: QueryStringParameters): Observable<PaginatedUser> {
    let params = new HttpParams()
    .set('PageNumber', pageParams.PageNumber)
    .set('PageSize', pageParams.PageSize);

    var orgStr = ''
    for (let i = 0; i < organizationIds.length; i++){
        orgStr += `OrgIDs=${organizationIds[i]}&`
    }
    orgStr = orgStr.substring(0, orgStr.length - 1);

    return this.http.get<PaginatedUser>(
      `${environment.apiEndpoint}/user/getByOrganization?${orgStr}`, {params}
    );
  }

  public searchUser(pageParams: QueryStringParameters, searchStr: string, organizationIds?: number[]): Observable<PaginatedUser>{
    const params = new HttpParams()
    .set('PageNumber', pageParams.PageNumber)
    .set('PageSize', pageParams.PageSize);

    var orgStr = ''
    if(organizationIds != null && organizationIds.length > 0){
      for (let i = 0; i < organizationIds.length; i++){
          orgStr += `OrgIDs=${organizationIds[i]}&`
      }
      orgStr = orgStr.substring(0, orgStr.length - 1);
    }

    return this.http.get<PaginatedUser>(
      `${environment.apiEndpoint}/user/searchUser/${searchStr}?${orgStr}`, {params}
    );
  }

  //Pull all users that belong to the same org(s) as the current user
  public getAssociatedUsers(pageParams: QueryStringParameters): Observable<PaginatedUser> {
    let params = new HttpParams()
    .set('PageNumber', pageParams.PageNumber)
    .set('PageSize', pageParams.PageSize);
   
    return this.http.get<PaginatedUser>(
      `${environment.apiEndpoint}/user/getAssociatedUsers`, {params}
    );
  }
}
