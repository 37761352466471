import { Component, Input, OnInit } from '@angular/core';
import { environment } from "src/environments/environment";

@Component({
  selector: 'vintage-frame',
  templateUrl: './vintage-frame.component.html',
  styleUrls: ['./vintage-frame.component.scss']
})
export class VintageFrameComponent implements OnInit {
  @Input() uri: string;
  @Input() frameHeight: number = 500;

  private startFlags = 'noHeader=True';
  
  public startUrl = '';
  private frameInitialized = false;
  public isLoading = true;


  ngOnInit(): void {
    this.genStartUrl();

    // needs to be set to true here because of timing
    // if we set this to true initially, frameloadedcallback() will execute before
    // ngOnInit gets completed
    this.frameInitialized=true;
  }

  genStartUrl() : void {

    //generate hostname + path
    const tmpStartUrl = environment.vintageFrameEndpoint + this.uri;


    // append startflags url params
    if (!this.startUrl.includes(this.startFlags)) {
      
      if (this.startUrl.includes('?')) { // if uri has existing params
        this.startUrl = tmpStartUrl + '&' + this.startFlags;
      } else {
        this.startUrl = tmpStartUrl + '?' + this.startFlags;
      }
    }
  }


  frameLoadedCallback(element) : void {
    // known issue that iframe page navigation is difficult to track because the
    // iframe.src value does not update on navigation change.
    // this results in the header showing on navigation within frame.
    // will play more later - mdqr
    
    if (this.frameInitialized) {
      this.isLoading = false;
    }
    if (!this.startUrl.includes('noHeader=True') && !this.isLoading) {
      this.isLoading = true;
      this.frameInitialized = false;
      this.genStartUrl();
    }
  }

}
