import { UserType } from "../enums/usertype";

export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  cai: string;
  organizationId:number;
  userType: UserType;
}

export class User implements IUser {
  public id = 0;
  public firstName = "";
  public lastName = "";
  public email = "";
  public cai = "";
  public organizationId : number;
  public userType: UserType;
  constructor(params?: IUser) {
    if (params) {
      this.id = params.id || this.id;
      this.firstName = params.firstName || this.firstName;
      this.lastName = params.lastName || this.lastName;
      this.email = params.email || this.email;
      this.cai = params.cai || this.cai;
      this.userType = params.userType || this.userType;
      this.organizationId= params.organizationId|| this.organizationId;
    }
  }
}
