<header>
  <!-- SEARCH -->
  <div id="search" role="search">
    <form id="search-form" action="https://www.office.com/search?auth=2" target="_blank" method="get">
      <em class="icon-search" aria-hidden="true"></em>
      <input id="search-text" autocomplete="off" type="text" name="q" spellcheck="false"
        placeholder="what can we help you find?" aria-label="what can we help you find?" />
      <button id="search-clear" type="reset" title="clear" aria-label="clear search text">
        clear
      </button>
      <button id="search-button" type="submit" title="search" aria-label="submit search">
        <em class="icon-arrow-right"></em>
      </button>
    </form>
  </div>

  <!-- TOP MENU TOGGLE -->
  <div mat-icon-button [matMenuTriggerFor]="menu" id="toggle-menu" class="topmenu" role="button"
    aria-label="Toggle top menu navigation" aria-pressed="false">
    <span class="icon-menu-hamburger"><em>{{ "HEADER.menu" | translate }}</em></span>
  </div>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>
      <span>business units</span>
    </button> 
    <button mat-menu-item>
      <span>organizations</span>
    </button>
    <button mat-menu-item>
      <span>bugs and enhacements</span>
    </button>
    <button mat-menu-item (click)="navigate('/inspectiontype')">
      <span>inspection templates</span>
    </button>
    <button mat-menu-item>
      <span>manage versions</span>
    </button>

    <!-- navbar items move here when viewport is small -->
    <ng-container *ngIf="width < 1200">
      <ng-container *ngFor="let item of headerItems">
        <button mat-menu-item *ngIf="!item.hasSubMenu" (click)="navigate(item.link)">
          <span>{{item.title | translate}}</span>
        </button>

        <ng-container *ngIf="item.hasSubMenu">
          <button mat-menu-item [matMenuTriggerFor]="subMenu">
            <span>{{item.title | translate}}</span>
          </button>
          <mat-menu #subMenu="matMenu">
            <ng-container *ngFor="let subItem of item.subMenuItems">
              <button mat-menu-item (click)="navigate(subItem.link)">
                <span>{{subItem.title | translate}}</span>
              </button>
            </ng-container>
          </mat-menu>
        </ng-container>
      </ng-container>
    </ng-container>

  </mat-menu>

  <!-- TOP MENU -->
  <div class="headernav" id="top-menu"></div>

  <!-- HALLMARK & SITE TITLE -->
  <div id="sitename">
    <a id="hallmark" href="/"></a>
    <h3>
      <a href="/">
        <h3 class="text-light-blue">inspections web portal</h3>
      </a>
    </h3>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-6">
        <div *ngIf="isLoggedIn()">
          <div *ngIf="loggedInUser | async as user; else loading_user">
            <h5 class="text-left">
              {{ "HEADER.welcome" | translate | uppercase }} {{ user.firstName | uppercase }} {{ user.lastName | uppercase }}
              
            </h5>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="row">
          <div class="col-12 text-right">
            <mat-form-field appearance="fill">
              <mat-label>{{ "HEADER.language" | translate }}</mat-label>
              <mat-select [(value)]="languageSelected" (selectionChange)="onLanguageChange()">
                <mat-option *ngFor="let option of languagesList" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- dynamically rendered navbar -->
  <nav *ngIf="width > 1200" id="site-specific-nav">
    <ul>
      <ng-container *ngFor="let item of headerItems">
        <li *ngIf="!item.hasSubMenu">
          <a routerLink="item.link">{{item.title | translate}}</a>
        </li>
        <li *ngIf="item.hasSubMenu">
          <a>{{item.title | translate}}</a>
          <ul>
            <ng-container *ngFor="let subItem of item.subMenuItems">
              <li *ngIf="subItem.parentMenuStr == item.subMenuStr">
                <a routerLink="{{subItem.link}}">{{subItem.title | translate}}</a>
              </li>
            </ng-container>
          </ul>
        </li>
      </ng-container>
    </ul>
  </nav>

</header>

<ng-template #loading_user>
  <div class="row">
    <div class="col-12">
      <h5 class="text-left"></h5>
    </div>
  </div>
</ng-template>