import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingIndicatorService {
  private statusSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public status$: Observable<boolean> = this.statusSubject$.asObservable();

  constructor() {this.show()}

  public show(): void {
    this.statusSubject$.next(true);
  }

  public hide(): void {
    this.statusSubject$.next(false);
  }
}
