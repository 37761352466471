<div class="query-builder">
    <!-- Initial value -->
    <div #treeContainer>
        <div *ngIf="data && data.rules">
            <ng-container *ngFor="let rule of data.rules; let i=index">
                <div class="row">
                    <div class="col-3">
                        <!-- Fields -->
                        <mat-form-field appearance="outline">
                            <mat-select required [(ngModel)]="rule.field"
                                (selectionChange)="changeField(rule.field, rule)" [ngModelOptions]="{standalone: true}"
                                [disabled]="rule.disabled">
                                <mat-option *ngFor="let field of getFields(rule.field)"
                                    [value]="field.value">{{field.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <!-- Operators -->
                        <mat-form-field appearance="outline">
                            <mat-select matNativeControl required [(ngModel)]="rule.operator"
                                (ngModelChange)="changeOperator(rule)">
                                <mat-option *ngFor="let operator of getOperators(rule.field)"
                                    [value]="operator">{{operator}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-3">
                        <!-- Inputs -->
                        <div [ngSwitch]="getInputType(rule.field, rule.operator)">
                            <mat-form-field class="example-full-width" *ngSwitchCase="'string'" appearance="outline">
                                <input matInput [(ngModel)]="rule.value" (ngModelChange)="changeInput()"
                                    [disabled]="false" type="text">
                            </mat-form-field>


                            <mat-form-field class="example-full-width" *ngSwitchCase="'number'" appearance="outline">
                                <input matInput [(ngModel)]="rule.value" (ngModelChange)="changeInput()"
                                    [disabled]="false" type="number">
                            </mat-form-field>


                            <mat-form-field class="example-full-width" *ngSwitchCase="'date'" appearance="outline">
                                <input matInput [(ngModel)]="rule.value" (ngModelChange)="changeInput()"
                                    [disabled]="false" type="date">
                            </mat-form-field>


                            <mat-form-field class="example-full-width" *ngSwitchCase="'time'" appearance="outline">
                                <input matInput [(ngModel)]="rule.value" (ngModelChange)="changeInput()"
                                    [disabled]="false" type="time">
                            </mat-form-field>


                            <mat-form-field *ngSwitchCase="'collection'" appearance="outline">
                                <mat-select required [(ngModel)]="rule.value" (selectionChange)="changeInput()"
                                    [disabled]="false">
                                    <mat-option *ngFor="let opt of getOptions(rule.field)" [value]="opt.id">
                                        {{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>


                            <mat-form-field *ngSwitchCase="'multiselect'" appearance="outline">
                                <mat-select required [(ngModel)]="rule.value" (selectionChange)="changeInput()"
                                    [disabled]="disabled" multiple>
                                    <mat-option *ngFor="let opt of getOptions(rule.field)" [value]="opt.id">
                                        {{opt.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <div *ngSwitchCase="'boolean'">
                                <mat-radio-group aria-label="boolean" [(ngModel)]="rule.value"
                                    (ngModelChange)="changeInput()">
                                    <mat-radio-button value="true">Yes</mat-radio-button>
                                    <mat-radio-button value="false">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="col-2">
                        <mat-radio-group aria-label="Conditional" [(ngModel)]="rule.condition"
                            (ngModelChange)="changeInput()">
                            <mat-radio-button value="AND">And</mat-radio-button>
                            <mat-radio-button value="OR">Or</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-1" *ngIf="!rule.disabled">
                        <mat-icon (click)="removeRule(rule, data)">remove_circle_outline</mat-icon>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <!-- Apply Filters -->
    <div class="row">
        <div class="col-2">
            <button type="button" mat-flat-button class="add-filter-button" (click)="addRule()">
                {{ "INSPECTION_LIST_FILTERS.addFilter" | translate }}
            </button>
        </div>
        <div class="col-10 text-right">
            <button type="button" mat-flat-button (click)="btnResetFilter()">
                {{ "INSPECTION_LIST_FILTERS.resetFilters" | translate }}
            </button>
            &nbsp;
            <button type="button" mat-flat-button color="primary" (click)="btnApplyFilters()">
                {{ "INSPECTION_LIST_FILTERS.applyFilters" | translate }}
            </button>
        </div>
    </div>
</div>